import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, } from "reactstrap"

import Header from "../components/header"
import Footer from "../components/footer"

const contact = css({
    maxWidth: 640,
    '& h1': {
        fontSize: 34,
        margin: "1rem 0 1rem"
    },
})

export default class Contact extends React.Component {
    render() {
        const { data } = this.props
        const { title, description, siteUrl, siteName } = data.site.siteMetadata
        const pageTitle = "Contact"
        const pageDescription = "お問い合わせはこちらから"
        return (
            <div>
                <Helmet>
                    <meta name="description" content={pageDescription + "｜" + description} />
                    <title>{pageTitle + "｜" + title}</title>
                    <link rel="canonical" href={siteUrl + "/contact"} />
                    <meta property="og:site_name" content={siteName} />
                    <meta property="og:title" content={pageTitle + "｜" + title} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={siteUrl + "/contact"} />
                    <meta property="og:image" content={siteUrl + "/images/ogp_image.png"} />
                    <meta property="og:description" content={pageDescription + "｜" + description}/>
                    <meta property="fb:app_id" content="912059452500297" />
                    <meta property="og:locale" content="ja_JP" />
                </Helmet>

                <Header/>

                <Container css={contact} className="px-md-5 px-sm-3 py-md-5 py-sm-3 text-center">
                    <h1 className="halant">Contact</h1>
                    <p>お仕事のご依頼・お問い合わせはこちらよりお送りください。<br/>１営業日以内にご返信させていただきます。</p>
                    <Form className="py-5 text-left" name="contact" method="POST" data-netlify="true">
                        <input type="hidden" name="form-name" value="contact" />
                        <Col className="px-0">
                            <FormGroup>
                                <Label className="halant">お名前</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="山田 太郎"
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-0">
                            <FormGroup>
                                <Label className="halant">E mail</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="example@the42.jp"
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-0">
                            <FormGroup>
                                <Label className="halant">お問い合わせ内容</Label>
                                <Input
                                    type="textarea"
                                    name="text"
                                    id="text"
                                    rows="10"
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-0 py-3 text-center">
                            <Button className="w-100" color="success" size="lg">送信する</Button>
                        </Col>
                    </Form>
                </Container>

                <Footer/>
            </div>
        )
    }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        description
      }
    }
  }
`
